.communityNew {
  background-color: #F7F7FA;
}
.communityNew h3 {
  font-size: 0.32rem;
  font-weight: bold;
  color: #373737;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityNew h3 .more {
  width: 0.6rem;
  text-align: center;
}
.communityNew .bottomColStyleP {
  font-size: 0.24rem;
  margin-top: 0.2rem;
}
.communityNew .bottomSpanStyles {
  /* text-align: center; */
  color: #999999;
  font-size: 0.3rem;
}
.communityNew .styleBottomVol {
  padding-bottom: 0.45rem;
}
.communityNew .bodydiv1Col2Style {
  color: #878787;
  margin-top: 0.05rem;
}
.communityNew .bottom {
  margin-top: 0.2rem;
  background: #ffffff;
  padding: 0 0.2rem;
  margin-bottom: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.communityNew .bottom .top {
  border-bottom: 0.01rem solid #D3D9DE;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.communityNew .bottom .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #3965FC;
}
.communityNew .bottom .top h3 {
  margin-bottom: 0;
}
.communityNew .van3Style {
  padding: 0.4rem 0;
}
.communityNew .van4Style {
  margin-top: 0.35rem;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 0.3rem;
}
.communityNew .row3Style {
  margin-bottom: 0.5rem;
}
.communityNew .row3Style span,
.communityNew .row3Style p {
  text-align: center;
  line-height: 0.48rem;
  display: block;
  font-size: 0.24rem;
}
.communityNew .row3Style span {
  font-size: 0.4rem;
  font-weight: 500;
  height: 0.48rem;
}
.communityNew .row3Style:nth-last-child(2),
.communityNew .row3Style:nth-last-child(1) {
  margin-bottom: 0;
}
.communityNew .row3Style.color1 span,
.communityNew .row3Style.color1 p {
  border-top: 0.03rem solid #ff4b04;
}
.communityNew .row3Style.color2 span,
.communityNew .row3Style.color2 p {
  border-top: 0.03rem solid #ff9e04;
}
.communityNew .bodyDiv1 {
  margin-top: 0.2rem;
  background: #ffffff;
  padding: 0 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.communityNew .bodyDiv1 .top {
  border-bottom: 0.01rem solid #D3D9DE;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.communityNew .bodyDiv1 .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #3965FC;
}
.communityNew .bodyDiv1 .top h3 {
  margin-bottom: 0;
}
.communityNew .content {
  background: #ffffff;
  padding: 0 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.communityNew .content .top {
  border-bottom: 0.01rem solid #D3D9DE;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityNew .content .top .t_left {
  display: flex;
  align-items: center;
}
.communityNew .content .top .t_left .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #3965FC;
}
.communityNew .content .top h3 {
  margin-bottom: 0;
}
.communityNew .content .top .t_right {
  margin-right: 0.2rem;
  color: #999999;
  font-size: 0.24rem;
}
.communityNew .content .top .t_right .icon_arrR {
  margin-left: 0.1rem;
  width: 0.2rem;
  height: 0.2rem;
  border-top: 0.04rem solid #999;
  border-right: 0.04rem solid #999;
}
.communityNew .content .content-member {
  padding: 0.4rem 0;
  text-align: center;
}
.communityNew .content .content-member .zhiTitle {
  font-size: 0.24rem;
}
.communityNew .content .content-member .zhiCent {
  font-size: 0.24rem;
  margin-bottom: 0.15rem;
  margin-top: 0.15rem;
  color: #666666;
  display: flex;
  align-items: center;
}
.communityNew .content .content-member .zhiCent .state img {
  width: 0.24rem;
  display: block;
  margin: 0 auto;
}
.communityNew .content .content-member .zhiCent .active {
  color: #2E62FF;
}
.communityNew .content .content-member .zhiCent .unactive {
  color: #6F7989;
}
.commonHead1 {
  background-image: url(../../assets/imgs/my_team.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
  padding: 0 0.3rem;
}
.commonHead1 .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
}
.commonHead1 .left {
  position: absolute;
  left: 0;
  top: 0;
}
.commonHead1 .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHead1 .left .icon .icon_arrL {
  border-top: 0.04rem solid #ffffff;
  border-left: 0.04rem solid #ffffff;
}
.commonHead1 .left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHead1 .title {
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  text-transform: capitalize;
}
.communityInfo {
  background-image: url(../../assets/imgs/my_team.png);
  background-repeat: no-repeat;
  background-size: 100% 2.6rem;
  margin-top: -0.88rem;
  height: 2.6rem;
}
.communityInfo .list {
  padding-top: 1.28rem;
  margin: 0 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityInfo .list .item {
  text-align: center;
}
.communityInfo .list .item .yen {
  font-size: 0.36rem;
  color: #FFFFFF;
}
.communityInfo .list .item p {
  margin-top: 0.08rem;
  color: #FFFFFF;
  font-size: 0.24rem;
}
.communityInfo .list .item1 {
  text-align: center;
}
.communityInfo .list .item1 .yen {
  font-size: 0.36rem;
  color: #FFFFFF;
}
.communityInfo .list .item1 p {
  margin-top: 0.08rem;
  color: #FFFFFF;
  font-size: 0.24rem;
}
